<template>
  <div class="com-home-QuestionTwo">
    <img
      class="img0"
      src="../../../../public/img/QuestionSolution/QuestionTwo/底纹.png"
    />
    <img
      class="img1"
      src="../../../../public/img/QuestionSolution/QuestionTwo/主文案@2x.png"
    />
    <img
      class="img2"
      src="../../../../public/img/QuestionSolution/QuestionTwo/网页第一层.png"
    />
    <img
      class="img3"
      src="../../../../public/img/QuestionSolution/QuestionTwo/网页第二层.png"
    />
    <img
      class="img4"
      src="../../../../public/img/QuestionSolution/QuestionTwo/橘色悬浮.png"
    />
    <img
      class="img5"
      src="../../../../public/img/QuestionSolution/QuestionTwo/蓝色悬浮.png"
    />
    <img
      class="img6"
      src="../../../../public/img/QuestionSolution/QuestionTwo/悬浮 文字@2x.png"
    />
    <img
      class="img7"
      src="../../../../public/img/QuestionSolution/QuestionTwo/蓝色悬浮内容.png"
    />
    <img
      class="img8"
      src="../../../../public/img/QuestionSolution/QuestionTwo/黄底-柱状图.png"
    />
    <img
      class="img9"
      src="../../../../public/img/QuestionSolution/QuestionTwo/蓝底-内容.png"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      this.$el.style.visibility = "visible";
      this.api.animateCSS(this.$el.querySelector(".img1"), "fadeInUp");
      this.api.animateCSS(this.$el.querySelector(".img2"), "fadeInUp");
      this.api.animateCSS(this.$el.querySelector(".img3"), "fadeInUp");
      this.api.animateCSS(this.$el.querySelector(".img4"), "fadeInUp");
      this.api
        .animateCSS(this.$el.querySelector(".img5"), "fadeInUp")
        .then(() => {
          const img7 = this.$el.querySelector(".img7");
          img7.style.display = "block";
          this.api.animateCSS(img7, "fadeIn");
        });
      this.api.animateCSS(this.$el.querySelector(".img6"), "fadeInUp");
    }, 100);
  },
};
</script>
<style lang="less" scoped>
.com-home-QuestionTwo {
  .img0 {
    margin-left: 100px;
    margin-top: 100px;
  }
  .img1 {
    width: 560px;
    margin-left: 150px;
    margin-top: 20px;
  }
  .img2 {
    margin-left: 170px;
    margin-top: 140px;
  }
  .img3 {
    margin-left: 200px;
    margin-top: 180px;
  }
  .img4 {
    margin-left: 550px;
    margin-top: 150px;
  }
  .img5 {
    margin-left: 100px;
    margin-top: 350px;
  }
  .img6 {
    width: 332px;
    margin-left: 490px;
    margin-top: 350px;
  }
  .img7 {
    margin-left: 155px;
    margin-top: 410px;
    display: none;
  }
  .img8 {
    margin-left: 598px;
    margin-top: 180px;
  }
  .img9 {
    margin-left: 155px;
    margin-top: 385px;
  }
}
</style>